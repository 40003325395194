@import "variables";

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
}

a {
  color: inherit;

  &:hover {
    text-decoration: none;
    color: $primary;
    transition: 0.3s color;
  }
}


ol {
  @media screen and (max-width: 768px)  {
    padding: 0 20px;
  }
}

.table td {
  vertical-align: middle;
}
