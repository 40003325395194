@import "variables";

.btn-primary, .btn-outline-primary:hover {
  color: $white;
}

button {
  &:focus {
    outline: none;
    box-shadow: none;
  }
}

.btn:focus, .btn.focus {
  box-shadow: none;
}
