.dish {
  &-remove-btn {
    margin-top: 32px;
  }

  &-card {
    &-body {
      min-height: 80px;
      max-height: 80px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-title {
      font-size: 18px;
      margin-bottom: 0;


      @media screen and (max-width: 768px) {
        font-size: 13px;
      }
    }

    &-image {
      width: 100%;
      padding-bottom: 100%;
      position: relative;

      &:after {
        content: '';
      }

      img {
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

