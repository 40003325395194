@import "../../styles/variables";

.dish {
  &-title {
    font-size: 40px;
    font-weight: 600;
    max-width: 70%;
    line-height: 1.1;
    margin-bottom: 10px;

    @media screen and (max-width: 768px) {
      font-size: 30px;
      max-width: 80%;
    }
  }

  &-summary-text {
    font-size: 16px;
    color: $gray-1;
    display: flex;
    align-items: center;

    &:first-child {
      margin-left: 0;
    }
  }

  &-summary-icon {
    font-size: 30px;
    margin-right: 10px;
  }

  &-ingredients-list {
    list-style: none;
    padding: 0;
    margin-bottom: 0;

    @media screen and (max-width: 768px) {
      padding: 0;
    }
  }

  &-subtitle {
    font-size: 20px;
    font-weight: 400;
    margin: 30px 0 20px;
    line-height: 1em;
  }

  &-element {
    color: #6d6d6d;
    font-size: 16px;

    ol {
      padding-left: 20px;
    }
  }

  &-accordion.card {
    border: none;
    margin: 0 -15px;

    .card-header {
      border-bottom: none;

      &--active {
        .btn {
          color: #8bb3b4;
        }
      }
    }

    .card-header, .card-body {
      padding-left: 15px;
      padding-right: 15px;
    }

    .btn {
      width: 100%;
      text-align: left;
      padding-left: 0;
      transition: color 0.15s;
    }
  }
}
