@import "variables";

input[type='checkbox'] {
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    cursor: pointer;
    padding: 0;
  }

  label {
    display: flex;
    align-items: center;
  }

  & + label:before {
    content: '';
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 20px;
    height: 20px;
    border: 1px solid $primary;
    border-radius: 0.25rem;
  }

  &:checked + label:before {
    background: $primary;
  }

  &:checked + label:after {
    content: '';
    position: absolute;
    left: 5px;
    top: 11px;
    background: $white;
    width: 2px;
    height: 2px;
    display: inline-block;
    box-shadow: 2px 0 0 $white,
    4px 0 0 $white,
    4px -2px 0 $white,
    4px -4px 0 $white,
    4px -6px 0 $white,
    4px -8px 0 $white;
    transform: rotate(45deg);
  }
}

.checkbox-checked {
  text-decoration: line-through;
}
