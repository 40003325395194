.dish-input-calorie {
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
}

.label {
  color: #8BB3B4;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 20px;
}

.diet {
  &-title {
    color: #8BB3B4;
    font-weight: 500;
    font-size: 24px;
  }

  &-icon {
    width: 20px;
    height: 20px;
  }

  &-info {
    display: flex;
  }

  &-info-text {
    font-size: 16px;
    color: #8a8a8a;
    margin: 0 10px 10px;
    font-weight: 500;

    &:first-child {
      margin-left: 0;
    }
  }

  &-dish-wrapper {
    margin: 20px 0;
  }

  &-dish-category {
    font-size: 20px;
    color: #FFC125;
    font-weight: 500;
  }

  &-dish {
    font-weight: 500;
  }

  &-tab {
    text-align: center;
    max-width: 140px;
  }

  &-tabs {
    justify-content: center;
    margin-bottom: 72px;
    margin-top: 56px;

    @media (max-width: 768px) {
      justify-content: space-between;
    }
  }

  &-tab-nav {
    font-size: 25px;
    display: flex;
    align-items: center;
  }
}

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
  border: none;
  color: #8bb3b4;
  font-weight: 500;
  position: relative;

  &:before {
    content: '';
    width: 100%;
    background-color: #8bb3b4;
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: transparent;
}
