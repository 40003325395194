.element {
  &-btn {
    display: inline-block;
    border: none;
    background: none;
    outline: none;
    font-size: 18px;

    &:hover {
      color: #8BB3B4;
      transition: color 0.3s;
    }
  }

  &-amount {
    color: #95999d;
  }

  &-label {
    position: relative;
    display: flex;
  }

  &-checked {
    text-decoration: line-through;
  }

  &-checkbox {
    position: absolute;
    top: 10px;
    width: 16px;
    height: 16px;
  }
}
