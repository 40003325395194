.dish {
  font-size: 14px;
  color: #000;

  &-date {
    color: #8BB3B4;
    font-weight: 500;
    font-size: 24px;
  }

  &-name {
    font-weight: 500;
  }

  &-summary {
    font-size: 14px;
    display: flex;
  }

  &-ingredient {
    font-size: 14px;
  }

  &-recipe {
    margin-left: -25px;
    font-size: 14px;
  }

  &-full-summary {
    display: flex;
    font-size: 16px;
  }
}

@media print{
  .diet-day {
    page-break-after: always;
  }

  .dish-prepare {
    display: none;
  }
}
