.page {
  &-header {
    display: flex;
    justify-content: space-between;
    font-size: 25px;
    align-items: center;
    font-weight: 600;

    &-search {
      margin-bottom: 30px;
    }

    &-name {
      font-size: 46px;
    }
  }
}
