.btn-diet {
  display: none;
  @media screen and (max-width: 768px) {
    display: inline-block;
    margin-right: 20px;
  }

  &-desktop {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
}
